<template>
    <div>
        <CSBreadcrumb />
        <div class="result-panel">
            <CSTable>
                <template v-slot:header>
                    <div class="table-header-panel">
                        实际应付金额: {{payAmount || '-'}}元
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>类目</th>
                        <th>应付金额</th>
                        <th>抵扣金额</th>
                        <th>实际应付金额</th>
                        <th>抵扣原因</th>
                        <th>渠道</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="(val, index) in payItems" :key="index">
                        <td  >{{val.name}}</td>
                        <td >{{val.payableAmount}}元</td>
                        <td >{{val.deductionAmount}}元</td>
                        <td >{{val.paidAmount}}元</td>
                        <td >{{val.comment || '-'}}</td>
                        <td >
                            <p class="btn btn-link btn-archive" style="font-size: 18px;   margin-bottom: 0;"
                               v-if="val.paymentArchive" @click="lookArchives"
                               :data-src="val.paymentArchive || [].toString()">
                                {{payChannel[val.paymentChannel]}}
                            </p>
                            <template v-else>-</template>
                            {{val.paymentOrderNo || ""}}
                        </td>
                    </tr>
                </template>
            </CSTable>
            <ViewImageModal />
        </div>
    </div>
</template>

<script>
import ViewImageModal from "@/components/ViewImageModal";
import {queryBillDetailUrl} from "@/requestUrl";
import {PAY_CHANNEL} from "../../../common/commonConst";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSTable from "@/components/common/CSTable";

export default {
    name: "BillDetail",
    props: {
        id: Number,
        payAmount: Number,
    },
    components: {
        CSTable,
        ViewImageModal,
        CSBreadcrumb,
    },
    data() {
        return {
            payChannel: {
                [PAY_CHANNEL.CASH_PAY]: "现金支付",
                [PAY_CHANNEL.BANK_PAY]: "银行转帐",
                [PAY_CHANNEL.ALI_PAY]: "支付宝转账",
                [PAY_CHANNEL.WE_CHAT_PAY]: "微信转账",
            },
            payItems: [],
        }
    },
    created() {
        this.queryBillPayDetailRequest();
    },
    methods: {
        lookArchives(e) {
            this.$vc.emit(this.$route.path,
                "viewImageModal",
                "view",
                e.target.dataset.src
            );
        },
        /**
         * 查看账单详情
         *  */
        queryBillPayDetailRequest() {
            return this.$fly
                .post(queryBillDetailUrl, {
                    regionId: this.$vc.getCurrentRegion().code,
                    operatorId: this.$vc.getCurrentStaffInfo().userId,
                    billId: this.id,
                })
                .then((res) => {
                    console.log(1);
                    console.log(res.data);
                     console.log(1);
                    this.payItems = res.data || [];
                    return res.data;
                });
        },
    }
}
</script>

<style scoped>

</style>
